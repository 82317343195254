@import "~/styles/responsive.scss";

.footer {
  background: var(--grey-900);
  padding: 52px 0 20px;
  position: relative;
  overflow: hidden;
  z-index: 0;
  color: white;

  .wrapper {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;

    @include devices(lg) {
      flex-direction: row;
      gap: 1.25rem;
    }

    .left {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 3rem;
    }

    .right {
      width: 100%;
      flex-shrink: 0;
      padding: 1.5rem;
      background: rgba(255, 255, 255, 0.05);
      border: 1px solid rgba(125, 130, 139, 0.5);
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 1.25rem;
      max-height: 312px;

      @include devices(lg) {
        width: 40%;
      }
    }

    a {
      transition: all 0.25s ease-in-out;

      :global(.pui-text) {
        color: inherit;
      }

      &:hover {
        color: var(--grey-50);
      }
    }
  }
}
