@import "~/styles/responsive.scss";

.header_container {
  position: sticky;
  top: 0;
  z-index: 99;
  width: 100%;
}

.discord_deal {
  position: relative;
  background-color: var(--grey-800);
  backdrop-filter: blur(50px);
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  color: white;
  overflow: hidden;

  strong {
    font-weight: 600;
  }

  a {
    font-weight: 600;
    text-decoration: underline;
    color: white;
  }
}

.header {
  padding: 1rem 0;
  transition: background-color 0.25s ease-in-out;
  top: 3rem;
  background-color: white;

  &.dark_mode {
    background-color: var(--grey-900);

    .nav {
      .menu_list {
        .menu_item {
          color: var(--grey-100);
        }
      }
    }
  }

  .header_logo {
    position: relative;
    width: 100px;
    aspect-ratio: calc(100 / 24);

    @include devices(md) {
      width: 150px;
    }
  }

  .nav {
    flex: 1;

    .menu_list {
      display: flex;

      @include devices(lg) {
        gap: 28px;
      }

      .menu_item {
        display: flex;
        flex-direction: column;
        row-gap: 0.5rem;
        font-size: 1rem;
        line-height: 150%;
        transition: color 0.25s ease-in-out;
        color: var(--grey-600);

        &:hover {
          color: var(--primary);
        }

        .label {
          display: flex;
          align-items: center;
          gap: 0.25rem;
          font-size: 1rem;
          line-height: 1.5;
          color: var(--grey-600);

          &.active {
            color: var(--primary);
          }
        }

        .submenu_list {
          display: flex;
          flex-direction: column;
          row-gap: 1rem;
          padding: 0.5rem 0 0 1rem;

          .submenu_item {
            font-size: 0.875rem;
            color: var(--grey-600);
            line-height: 1.5;

            &:hover,
            &.active {
              color: var(--primary);
            }
          }
        }

        .submenu {
          box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.08);
          border: none;
          border-radius: 0.5rem;
          padding: 0.75rem;

          .submenu_item {
            transition: all 0.25s ease-in-out;
            padding: 0;
            min-width: 160px;
            white-space: nowrap;
            color: var(--grey-800);

            &:hover {
              color: var(--primary);
              background-color: white;
            }
          }
        }

        .menu_divider {
          margin-top: 0.75rem;
          margin-bottom: 0.75rem;
        }
      }
    }
  }
}

.hamburger {
  position: relative;
  width: 2.5rem;
  height: 2.5rem;
  background-color: white;
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include devices(lg) {
    display: none;
  }

  &.active {
    .line {
      transition: all 0.5s;

      &:nth-child(1) {
        top: 2px;
        transform: rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:nth-child(3) {
        top: -2px;
        transform: rotate(-45deg);
      }
    }
  }

  .line {
    display: block;
    position: relative;
    width: 50%;
    height: 2px;
    background-color: rgba(0, 0, 0, 1);
    transition: all 0.5s;

    &:nth-child(1) {
      top: -4px;
    }

    &:nth-child(3) {
      top: 4px;
    }
  }
}

.mobile_header {
  width: 100%;
  height: 100%;
  position: fixed;
  background-color: white;
  transform: translateX(-100%);
  top: 0;
  transition: all 0.25s ease-in-out;
  z-index: 9999;

  &.open {
    transform: translateX(0);
  }

  .nav {
    .menu_list {
      flex-direction: column;

      .menu_item {
        padding: 1rem 0;

        &:not(:last-child) {
          border-bottom: 1px solid var(--grey-100);
        }
      }
    }
  }
}

.menu_item_target {
  position: relative;

  &:hover {
    .submenu {
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    }
  }

  .submenu {
    position: absolute;
    left: 50%;
    top: 100%;
    z-index: 9999;
    background-color: white;
    transform: translateX(-50%);
    opacity: 0;
    transition: all 0.25s ease-in-out;
    visibility: hidden;
    pointer-events: none;
  }
}
