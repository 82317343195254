.btn {
  --btn-bg-color: var(--primary);
  --btn-bg-hover-color: #2b3eb9;
  --btn-border-color: var(--primary);
  --btn-border-hover-color: #2b3eb9;
  --btn-color: white;
  --btn-hover-color: white;
  height: 2.5rem;
  font-size: 0.875rem;
  line-height: 1.875rem;
  border-radius: 0.5rem;
  font-weight: 600;
  padding: 0 0.75rem;
  position: relative;
  transition: all 0.25s ease-in-out;
  border: 1px solid var(--btn-border-color);
  background: var(--btn-bg-color);
  color: var(--btn-color);
  display: flex;
  align-items: center;
  justify-content: center;

  &.large {
    height: 3rem;
    font-size: 1rem;
  }

  &.square {
    width: 2.25rem;
    height: 2.25rem;
  }

  &:hover {
    border-color: var(--btn-border-hover-color);
    background: var(--btn-bg-hover-color);
    color: var(--btn-hover-color);
  }

  &.disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  &.solid {
    &.secondary {
      --btn-bg-color: var(--grey-900);
      --btn-bg-hover-color: #2a2a2a;
      --btn-border-color: var(--grey-900);
      --btn-border-hover-color: #2a2a2a;
    }

    &.danger {
      --btn-bg-color: var(--accent-red);
      --btn-bg-hover-color: #d53b3e;
      --btn-border-color: var(--accent-red);
      --btn-border-hover-color: #d53b3e;
    }
  }

  &.outline {
    --btn-bg-color: transparent;
    --btn-color: var(--primary);
    --btn-hover-color: var(--primary);

    &:hover {
      --btn-bg-hover-color: #e6ecfb;
      --btn-border-hover-color: var(--primary);
    }

    &.secondary {
      --btn-border-color: var(--grey-900);
      --btn-color: var(--grey-900);

      &:hover {
        --btn-bg-hover-color: var(--grey-50);
        --btn-border-hover-color: var(--grey-900);
        --btn-hover-color: var(--grey-900);
      }
    }

    &.danger {
      --btn-border-color: var(--accent-red);
      --btn-color: var(--accent-red);

      &:hover {
        --btn-bg-hover-color: var(--accent-light-red);
        --btn-border-hover-color: var(--accent-red);
        --btn-hover-color: var(--accent-red);
      }
    }

    &.white {
      --btn-border-color: white;
      --btn-color: white;

      &:hover {
        --btn-bg-hover-color: white;
        --btn-border-hover-color: white;
        --btn-hover-color: var(--primary);
      }
    }
  }

  &.borderless {
    --btn-bg-color: transparent;
    --btn-border-color: transparent;
    --btn-border-hover-color: transparent;
    --btn-color: var(--primary);
    --btn-hover-color: var(--primary);
    --btn-bg-hover-color: rgba(23, 24, 26, 0.08);

    &.secondary {
      --btn-color: var(--grey-900);
      --btn-hover-color: var(--grey-900);
    }

    &.danger {
      --btn-color: var(--accent-red);
      --btn-hover-color: var(--accent-red);
    }

    &.square {
      --btn-color: var(--grey-400);
      --btn-bg-hover-color: var(--primary-50);
      --btn-hover-color: var(--primary);
    }
  }

  &.icon {
    padding: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
    border-color: transparent;
    color: var(--grey-400);

    &:hover {
      color: var(--primary);
    }

    &.danger {
      color: var(--accent-red);

      &:hover {
        color: var(--accent-red);
      }
    }
  }
}
